import { Storage } from './definition';

type LocalStorageOptions = {
  namespace: string;
};

type CreateLocalStorage = <T>(options: LocalStorageOptions) => Storage<T>;

const createLocalStorage: CreateLocalStorage = ({ namespace }) => ({
  get() {
    return JSON.parse(localStorage.getItem(namespace)!);
  },
  set(data) {
    localStorage.setItem(namespace, JSON.stringify(data));
  },
  subscribe(fn) {
    const onChange = (event: StorageEvent) => {
      if (event.oldValue !== event.newValue) {
        fn(JSON.parse(event.newValue!));
      }
    };

    window.addEventListener('storage', onChange);

    return () => {
      window.removeEventListener('storage', onChange);
    };
  },
});

export default createLocalStorage;
