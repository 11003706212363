// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-conditions-generales-tsx": () => import("./../../../src/pages/conditions-generales.tsx" /* webpackChunkName: "component---src-pages-conditions-generales-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-shop-panier-confirmation-tsx": () => import("./../../../src/pages/shop/panier/confirmation.tsx" /* webpackChunkName: "component---src-pages-shop-panier-confirmation-tsx" */),
  "component---src-pages-shop-panier-index-tsx": () => import("./../../../src/pages/shop/panier/index.tsx" /* webpackChunkName: "component---src-pages-shop-panier-index-tsx" */),
  "component---src-templates-shop-adele-tsx": () => import("./../../../src/templates/shop/adele.tsx" /* webpackChunkName: "component---src-templates-shop-adele-tsx" */),
  "component---src-templates-shop-astro-tsx": () => import("./../../../src/templates/shop/astro.tsx" /* webpackChunkName: "component---src-templates-shop-astro-tsx" */),
  "component---src-templates-shop-frida-tsx": () => import("./../../../src/templates/shop/frida.tsx" /* webpackChunkName: "component---src-templates-shop-frida-tsx" */),
  "component---src-templates-shop-luna-tsx": () => import("./../../../src/templates/shop/luna.tsx" /* webpackChunkName: "component---src-templates-shop-luna-tsx" */),
  "component---src-templates-shop-product-tsx": () => import("./../../../src/templates/shop/product.tsx" /* webpackChunkName: "component---src-templates-shop-product-tsx" */),
  "component---src-templates-shop-rosa-tsx": () => import("./../../../src/templates/shop/rosa.tsx" /* webpackChunkName: "component---src-templates-shop-rosa-tsx" */)
}

