import React from 'react';
import useUpdate from '../../components/useUpdate';
import { Store } from '../store/definition';
import { State, Action } from './definition';
import { reducer, initialState } from './reducer';

type Context = [State, React.Dispatch<Action>];

const context = React.createContext<Context>([initialState, () => {}]);

const useCart = () => React.useContext<Context>(context);

type Props = {
  store: Store<State>;
};

const Provider: React.FC<Props> = ({ store, children }) => {
  const contextValue = React.useReducer(reducer, initialState, (state) => {
    return store.get() || state;
  });

  useUpdate(() => {
    store.set(contextValue[0]);
  }, [contextValue]);

  React.useEffect(() => {
    return store.subscribe((state) => {
      const action: Action = state
        ? { type: 'REPLACE', state: state }
        : { type: 'RESET' };

      contextValue[1](action);
    });
  }, []);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default {
  Provider,
  useCart,
};
