import React from 'react';

const useUpdate = (fn: React.EffectCallback, deps?: React.DependencyList) => {
  const initial = React.useRef(true);

  React.useEffect(() => {
    if (initial.current) {
      // The effect has to run only on update.
      initial.current = false;
      return;
    }

    return fn();
  }, deps);
};

export default useUpdate;
