import './src/styles/reset.css';
import './src/styles/artwork.css';
import './src/styles/button.css';
import './src/styles/border.css';
import './src/styles/color.css';
import './src/styles/control.css';
import './src/styles/font.css';
import './src/styles/layout.css';
import './src/styles/size.css';
import './src/styles/spacing.css';

import React from 'react';
import createLocalStorage from './src/modules/store/localStorage';
import createStore from './src/modules/store/store';
import Cart from './src/modules/cart/Cart';

const storage = createLocalStorage({ namespace: 'po:cart' });
const store = createStore(storage, {
  TTL: 3600 * 72 * 1000, // 72h
  version: 2,
});

export const wrapRootElement = ({ element }) => (
  <Cart.Provider store={store}>{element}</Cart.Provider>
);
