import { Store, StoreValue, StoreOptions, Storage } from './definition';

const isEqual = (previous: unknown, next: unknown): boolean =>
  JSON.stringify(previous) === JSON.stringify(next);

const createStore = <T>(
  storage: Storage<StoreValue<T>>,
  options: StoreOptions
): Store<T> => ({
  get() {
    const state = storage.get();
    if (!state) {
      return null;
    }

    // The value is not optional but IRL it could be. We have to deal with the legacy
    // format which didn't contain the version number. Hence the fallback to a zero
    // value.
    const version = state.version || 0;
    if (version < options.version) {
      return null;
    }

    if (Date.now() > state.updatedAt + options.TTL) {
      return null;
    }

    return state.data;
  },
  set(data) {
    const previousState = storage.get();
    if (previousState && isEqual(previousState.data, data)) {
      // Prevent update if the data is equal. Otherwise, the storage updates its
      // value forever. The `updateAt` field change at each call. The alternative
      // is to expect the field on the data but it leaks implementation details
      // outside the store.
      return;
    }

    storage.set({
      updatedAt: Date.now(),
      version: options.version,
      data,
    });
  },
  subscribe(fn) {
    return storage.subscribe((state) => {
      fn(state ? state.data : null);
    });
  },
});

export default createStore;
