import { State, Action } from './definition';

export const MAX_QUANTITY = 5;

export const initialState: State = {
  // FIXME
  coupon: null,
  ids: [],
  byId: {},
};

export const reducer: React.Reducer<State, Action> = (prevState, action) => {
  switch (action.type) {
    case 'ADD': {
      const item = prevState.byId[action.product.variant.id];
      if (item) {
        return {
          ...prevState,
          byId: {
            ...prevState.byId,
            [item.product.variant.id]: {
              ...item,
              quantity: Math.min(item.quantity + action.quantity, MAX_QUANTITY),
            },
          },
        };
      }

      return {
        ...prevState,
        ids: prevState.ids.concat(action.product.variant.id),
        byId: {
          ...prevState.byId,
          [action.product.variant.id]: {
            product: action.product,
            quantity: Math.min(action.quantity, MAX_QUANTITY),
          },
        },
      };
    }

    case 'UPDATE': {
      const item = prevState.byId[action.id];
      if (!item) {
        return prevState;
      }

      return {
        ...prevState,
        byId: {
          ...prevState.byId,
          [action.id]: {
            ...item,
            quantity: Math.min(action.quantity, MAX_QUANTITY),
          },
        },
      };
    }

    case 'REMOVE': {
      const { [action.id]: _, ...byId } = prevState.byId;

      return {
        ...prevState,
        ids: prevState.ids.filter((_) => _ !== action.id),
        byId,
      };
    }

    // FIXME
    case 'COUPON_ADD': {
      return {
        ...prevState,
        coupon: action.coupon,
      };
    }

    // FIXME
    case 'COUPON_REMOVE': {
      return {
        ...prevState,
        coupon: null,
      };
    }

    case 'REPLACE': {
      return action.state;
    }

    case 'RESET': {
      return initialState;
    }

    default: {
      return prevState;
    }
  }
};
